<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex inputs-wrap justify-between overflow-y-auto">
      <ul
        class="w-2/6 overflow-y-auto p-8 bg-gray-100 text-black"
        aria-label="notes"
      >
        <li>
          the field name is how the field will be known internally to the rest of the
          application.
        </li><li>
          the field label is how the field will be known to the rest of the
          application, use a significant name.
        </li>
        <li>choose the date type of that specific field.</li>
        <li>describes with detail what the field is used for.</li>
      </ul>
      <div class="w-4/6 px-4 mx-4 overflow-y-auto">
        <div class="w-full flex gap-4 grid grid-cols-2">
          <div>
            <label for="field_name" class="font-bold">field name</label>
            <input
              name="field_name"
              type="text"
              class="form-input"
              v-model="field.name"
            />
            <span v-if="hasError('field.name')" class="form-error">
              {{ showError("field.name") }}
            </span>
          </div>
          <div>
            <label for="field_label" class="font-bold">field label</label>
            <input
              name="field_label"
              type="text"
              class="form-input"
              v-model="field.label"
            />
            <span v-if="hasError('field.label')" class="form-error">
              {{ showError("field.label") }}
            </span>
          </div>
          <div>
            <label class="flex flex-col font-bold">
            document name:
            <dropdown
              :options="documents"
              :placeholder="'select partner document'"
              v-model="field.document"
            ></dropdown>
          </label>
            <span v-if="hasError('field.document')" class="form-error">
              {{ showError("field.document") }}
            </span>
          </div>
          <div>
            <label class="flex flex-col font-bold">
            data type:
            <dropdown
              :options="data_types"
              :placeholder="'select partner document'"
              v-model="field.data_type"
            ></dropdown>
          </label>
            <span v-if="hasError('field.data_type')" class="form-error">
              {{ showError("field.data_type") }}
            </span>
          </div>
        </div>
        <div>
          <div class="mt-2">
            <label for="description" class="font-bold">description</label>
            <textarea
              rows="3"
              type="text"
              class="form-input"
              name="description"
              v-model="field.description"
            />
            <span v-if="hasError('field.description')" class="form-error">
              {{ showError("field.description") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <modal-footer :footer="footer" :primary="handleSave" :tertiary="cancel" />
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import Dropdown from "@/components/ui/Dropdown";
import { mapGetters } from "vuex";

export default {
  name: "AddNewPartnerDocument",
  components: { Loader, Dropdown, ModalFooter },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  data() {
    return {
      loading: false,
      partner: '',
      field: {
        name: "",
        data_type: null,
        description: "",
        label: '',
        document: null
      },
      documents: [],
      footer: {
        tertiaryButton: "cancel",
        primaryButton: "save",
      },
    };
  },
  computed: {
    ...mapGetters({
      getPartner: 'partner_documents/getPartner'
    }),
    data_types() {
      return [{
        key: 'string',
        value: 'string'
      },
      {
        key: 'numeric',
        value: 'numeric'
      },
      {
        key: 'date',
        value: 'date'
      },
      {
        key: 'boolean',
        value: 'boolean'
      }]
    }
  },
  methods: {
    async handleSave() {
      this.loading = true;
      await Promise.all([
        this.save(),
        this.saveFieldtoDoc()
      ])
      .then(() => {
        this.cancel();
      })
      .catch((error) => {
        this.notifyError(error.message)
      })
      .finally(() => this.loading = false);
    },
    save() {
      this.$leasingDataProvider.create('partner_field', {
        data: {
          partner_id: this.getPartner,
          partner_field_name: this.field.name,
          partner_field_label: this.field.label,
          partner_field_data_type: this.field.data_type,
          description: this.field.description

        }
      })
      .then((res) => {
        if (res.status === 201) {
          this.notifySuccess("the document was created successfully");
        }
      })
      .catch(() => {
        throw Error('Something went wrong creating field, please try again later.');
      })
      .finally(() => this.loading = false);
    },
    saveFieldtoDoc() {
      this.$leasingDataProvider.create('document_field_map', {
          partner_id: this.getPartner,
          partner_document_id: this.field.document,
          data: {
            partner_field_name: this.field.name
          }
        })
        .then((res) => {
          if (res.status === 201) {
            this.notifySuccess("the field was added successfully");
          }
        })
        .catch(() => {
          throw Error('Something went wrong adding field to document, please try again later.');
        })
        .finally(() => this.loading = false);
    },
    fetchDocuments() {
      this.$leasingDataProvider.getList('partner_document')
      .then((res) => {
        if (res.status === 200) {
          this.documents = res.data
          .filter(doc => doc.partner_id === this.getPartner)
          .map(doc => {
            return {
              ...doc,
              key: doc.partner_document_id,
              value: doc.document_name
            };
          });
        }
      })
      .catch(() => {
        this.notifyError('Something went wrong, please try again later.')
      })
      .finally(() => this.loading = false);
    },
    cancel() {
      this.$router.push({ name: "manage-partner-documents.index" });
    },
  },
  validations: {
    "field.name": "required",
    "field.label": "required",
    "field.category": "required",
    "field.description": "required",
    "field.document": "required"
  },
  created() {
    this.initValidator();
    this.fetchDocuments();
  },
};
</script>
<style scoped>
ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
.isDisabled {
  @apply text-gray-400;
}
.inputs-wrap {
  @apply pb-2;
  height: calc(72vh - var(--menuHeight));
  overflow-y: auto;
}
</style>
